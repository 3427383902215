import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api";
import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import { toast } from "react-toastify";

export const getAllDocs = createAsyncThunk(
  "docs/all",
  async (
    { stock_ticker, start_date, end_date },
    { rejectWithValue, getState }
  ) => {
    const url = apiEndpoints.GET_ALL_DOCS;

    const body = {
      stock_ticker: stock_ticker ? stock_ticker : "",
      start_date: start_date ? start_date : "",
      end_date: end_date ? end_date : "",
    };

    const token = getState().user.token;

    try {
      const { data } = await api.post(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error) {
      toast.clearWaitingQueue();
      toast.dismiss();

      toast.error(
        error.response?.data || error.message || "Failed to fetch docs"
      );
      return rejectWithValue(
        error.response?.data || error.message || "Failed to fetch docs"
      );
    }
  }
);
