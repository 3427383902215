import { createSlice } from "@reduxjs/toolkit";
import { getAllDocs } from "../actions/getAllDocs";
import { uploadFiles } from "../actions/uploadFiles";
import { getChatHistory } from "../actions/getChatHistory";

const initialState = {
  allDocs: [],
  selectedDocs: [],
  selectedCategories: [],
  isLoading: false, // To track the loading state
  error: null, // To store any error messages
  isShowingPDF: false,
  fileLink: "",
};

const docsSlice = createSlice({
  name: "docs",
  initialState,
  reducers: {
    setSelectDocs(state, action) {
      state.selectedDocs = action.payload;
    },
    setSelectCategories(state, action) {
      state.selectedCategories = action.payload; // Set selected categories
    },
    setShowPdf(state, action) {
      const link = action.payload;
      state.fileLink = link;
      state.isShowingPDF = true;
    },
    setClosePdf(state, action) {
      state.fileLink = "";
      state.isShowingPDF = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDocs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllDocs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allDocs = action?.payload?.documents; // Update state with the fetched documents
      })
      .addCase(getAllDocs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch documents";
      })
      // Handling uploadFiles actions
      .addCase(uploadFiles.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadFiles.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(uploadFiles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to upload files";
      })

      .addCase(getChatHistory.fulfilled, (state, action) => {
        const documentsUsed = action.payload.documents_used.map(
          (doc) => doc?.document_id
        );
        state.selectedDocs = documentsUsed;
        state.selectedCategories = action.payload.categories ?? [];
      });
  },
});

export const { setSelectDocs, setSelectCategories, setClosePdf, setShowPdf } =
  docsSlice.actions;

export default docsSlice.reducer;
