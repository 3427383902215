import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./screens/auth/login";
import Chat from "./screens/chat";
import NotFound from "./screens/notFound";
import ProtectedRoute from "./layouts/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/app" element={<ProtectedRoute element={<Chat />} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <ToastContainer
        limit={1}
        hideProgressBar
        theme="colored"
        autoClose={4000}
      />
    </>
  );
};

export default App;
