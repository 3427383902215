import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearActiveChat,
  getAllStockTickers,
  setSelectedStock,
} from "../../store";

const useChat = () => {
  const dispatch = useDispatch();
  const { selectedStock } = useSelector((state) => state.stocks);
  const { isShowingPDF } = useSelector((state) => state.docs);
  const [panelWidth, setPanelWidth] = useState(250);

  //changing the browser title
  useEffect(() => {
    document.title = "Munshot | Chat";
  }, []);

  useEffect(() => {
    dispatch(getAllStockTickers());
  }, []);

  const handleSearchStockName = (item) => {
    dispatch(setSelectedStock(item));
    dispatch(clearActiveChat());
  };

  const handleMouseDown = (e) => {
    const startX = e.clientX;
    const startWidth = panelWidth;

    // Add a class to disable text selection and pointer events
    document.body.classList.add("no-select");

    const handleMouseMove = (e) => {
      const newWidth = startWidth + (e.clientX - startX);
      if (newWidth >= 250 && newWidth <= 500) {
        setPanelWidth(newWidth); // Set the new width within constraints
      }
    };

    const handleMouseUp = () => {
      // Remove the no-select class after resizing
      document.body.classList.remove("no-select");

      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return {
    handleSearchStockName,
    isShowingPDF,
    panelWidth,
    handleMouseDown,
    selectedStock,
  };
};
export default useChat;
