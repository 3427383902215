import axios from "axios";
import config from "../utils/config";
import { logoutUser } from "../store";
import store from "../store";
import { toast } from "react-toastify";

const axiosParams = {
  baseURL: config.API_URL,
};

const axiosInstance = axios.create(axiosParams);

const requestInterceptor = (request) => {
  return request;
};

const requestErrorInterceptor = (error) => {
  console.log(error);
  throw error;
};

const responseInterceptor = (response) => {
  return response;
};

const responseErrorInterceptor = (error) => {
  if (error?.response?.status === 401) {
    toast.clearWaitingQueue();
    toast.dismiss();

    store.dispatch(logoutUser());
  }
  if (error?.response?.status === 502) {
    toast.clearWaitingQueue();
    toast.dismiss();

    toast.error("Bad Gateway-Time out | 502");
  }

  throw error;
};

axiosInstance.interceptors.request.use(
  requestInterceptor,
  requestErrorInterceptor
);
axiosInstance.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor
);

const api = (axios) => ({
  get: (url, config = {}) => requestWrapper(axios.get(url, config)),
  delete: (url, config = {}) => requestWrapper(axios.delete(url, config)),
  post: (url, body, config = {}) =>
    requestWrapper(axios.post(url, body, config)),
  patch: (url, body, config = {}) =>
    requestWrapper(axios.patch(url, body, config)),
  put: (url, body, config = {}) => requestWrapper(axios.put(url, body, config)),
});

// Wrapper function to handle the response and errors
const requestWrapper = async (request) => {
  try {
    const response = await request;
    return response.data;
  } catch (error) {
    throw error; // This ensures that errors are propagated
  }
};

export default api(axiosInstance);
