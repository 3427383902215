import styles from "./myData.module.css";
import { useEffect, useState, useCallback } from "react";
import UploadDocument from "../../../components/UploadDocument";
import { IoFilter } from "react-icons/io5";
import Filter from "../../../components/Filter";
import { getAllDocs, setSelectDocs, setSelectCategories } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useAction } from "../../../hooks/useActions";
import Skeleton from "../../skeleton";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { setShowPdf } from "../../../store";

const MyData = () => {
  const dispatch = useDispatch();
  const { allDocs, selectedDocs, selectedCategories } = useSelector(
    (state) => state.docs
  );
  const { activeChat } = useSelector((state) => state.chats);
  const { selectedStock } = useSelector((state) => state.stocks);
  const [getAllDocsAction, , isDocsLoading] = useAction(getAllDocs);
  const [selectedOption, setSelectedOption] = useState("All Time");
  const [allSelected, setAllSelected] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    getAllDocsAction({ stock_ticker: selectedStock?.ticker });
  }, [selectedStock]);

  useEffect(() => {
    const allSelected = allDocs?.every((item) =>
      selectedCategories.includes(item.category)
    );
    setAllSelected(allSelected);
  }, [selectedDocs, allDocs, selectedCategories]);

  const handleSelectAll = () => {
    if (allSelected) {
      dispatch(setSelectCategories([]));
    } else {
      const allCategories = allDocs.map((item) => item.category);
      const uniqueCategories = [...new Set(allCategories)]; // Ensure categories are unique
      dispatch(setSelectCategories(uniqueCategories));
    }
  };

  const handleItemSelect = (index, docId = null) => {
    if (docId === null) {
      //  select the category, if the category is selected then check all its child and vice versa
      const updatedCategories = selectedCategories.includes(
        allDocs[index].category
      )
        ? selectedCategories.filter(
            (category) => category !== allDocs[index].category
          )
        : [...selectedCategories, allDocs[index].category];

      dispatch(setSelectCategories(updatedCategories));
      const updatedDocs = selectedDocs.filter(
        (docId) => !allDocs[index].content.some((doc) => doc.id === docId)
      );
      dispatch(setSelectDocs(updatedDocs));
    } else {
      const isCategorySelected = selectedCategories.includes(
        allDocs[index].category
      );
      if (isCategorySelected) {
        dispatch(
          setSelectCategories(
            selectedCategories.filter(
              (category) => category !== allDocs[index].category
            )
          )
        );
        const selectPendingDocs = allDocs[index].content
          .filter((doc) => doc.id !== docId)
          .map((doc) => doc.id);
        dispatch(setSelectDocs(selectPendingDocs));
      } else {
        const updatedDocsList = selectedDocs.includes(docId)
          ? selectedDocs.filter((id) => id !== docId)
          : [...selectedDocs, docId];
        const willCategorySelect = allDocs[index].content.every((doc) =>
          updatedDocsList.includes(doc.id)
        );

        if (willCategorySelect) {
          const updatedDocsListWithoutCategoryDocs = updatedDocsList.filter(
            (id) => !allDocs[index].content.some((doc) => doc.id === id)
          );
          dispatch(setSelectDocs(updatedDocsListWithoutCategoryDocs));
          dispatch(
            setSelectCategories([
              ...selectedCategories,
              allDocs[index].category,
            ])
          );
        } else {
          dispatch(setSelectDocs(updatedDocsList));
        }
      }
    }
  };

  // Toggle expand/collapse for categories
  const handleToggle = useCallback(
    (index) => {
      setExpandedItems((prev) => ({ ...prev, [index]: !prev[index] }));
    },
    [setExpandedItems]
  );

  // Handle filter toggle
  const toggleFilter = () => setShowFilter(!showFilter);

  // Handle filter application
  const filterHandler = ({ option, dateRange }) => {
    getAllDocsAction(dateRange);
    setSelectedOption(option);
    setShowFilter(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <div className={styles.checkbox_wrapper}>
          <input
            className={styles.checkbox}
            type="checkbox"
            checked={allSelected}
            onChange={handleSelectAll}
            disabled={activeChat != null}
          />
          <label className={styles.label}>My Data</label>
        </div>
        <div className={styles.filter}>
          <IoFilter
            size={20}
            className={styles.filterIcon}
            onClick={toggleFilter}
          />
          <UploadDocument setSelectedOption={setSelectedOption} />
          {showFilter && (
            <Filter
              selectedOption={selectedOption}
              filterHandler={filterHandler}
            />
          )}
        </div>
      </div>

      {isDocsLoading ? (
        <Skeleton width="100%" height="30px" gap="15px" />
      ) : allDocs?.length ? (
        <div className={styles.docs_wrapper}>
          {allDocs.map((item, index) => (
            <div key={index}>
              <div className={styles.doc_wrapper}>
                <div className={styles.checkbox_wrapper}>
                  <input
                    className={styles.checkbox}
                    disabled={activeChat != null}
                    type="checkbox"
                    checked={selectedCategories.includes(item?.category)}
                    onChange={() => handleItemSelect(index)}
                  />
                  <label
                    onClick={() => handleToggle(index)}
                    className={styles.label}
                  >
                    {item?.category.replaceAll("_", " ")}
                  </label>
                </div>
                {expandedItems[index] ? (
                  <FaAngleUp onClick={() => handleToggle(index)} />
                ) : (
                  <FaAngleDown onClick={() => handleToggle(index)} />
                )}
              </div>
              {expandedItems[index] &&
                item?.content?.map((doc) => (
                  <div key={doc.id} className={styles.expanded_content}>
                    <div className={styles.checkbox_wrapper}>
                      <input
                        disabled={activeChat != null}
                        className={styles.checkbox}
                        type="checkbox"
                        checked={
                          selectedDocs.includes(doc.id) ||
                          selectedCategories.includes(item?.category)
                        }
                        onChange={() => handleItemSelect(index, doc.id)}
                      />
                      <label
                        className={styles.label}
                        onClick={() => dispatch(setShowPdf(doc?.link))}
                      >
                        {doc.title}
                      </label>
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.noData}>Add Data +</div>
      )}
    </div>
  );
};

export default MyData;
