import { createAsyncThunk } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import api from "../../services/api";
import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import { toast } from "react-toastify";

// Define the PropTypes shape for the filters object
const filtersPropTypes = {
  limit: PropTypes.number,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  stock_ticker: PropTypes.string,
  offset: PropTypes.number,
};

function validateFilters(filters) {
  if (filters) {
    PropTypes.checkPropTypes(filtersPropTypes, filters, "prop", "getAllChats");
  }
}

export const getAllChats = createAsyncThunk(
  "chats/all",
  async (filters, { rejectWithValue, getState }) => {
    try {
      validateFilters(filters);

      const url = apiEndpoints.GET_ALL_CHATS;
      const token = getState().user.token;

      const { data } = await api.get(url, {
        params: filters,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return data;
    } catch (error) {
      toast.clearWaitingQueue();
      toast.dismiss();

      toast.error(
        error.response?.data || error.message || "Failed to fetch chats"
      );
      return rejectWithValue(
        error.response?.data || error.message || "Failed to fetch chats"
      );
    }
  }
);
