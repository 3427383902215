import "./index.css";

import { BsSendFill } from "react-icons/bs";

const SuggestionBox = ({ onItemPressHandler, questions }) => {
  const SuggestionItem = ({ data, lastItem }) => {
    return (
      <div className="suggestion-item" onClick={() => onItemPressHandler(data)}>
        {/* <p>{removeMarkdown(data)}</p> */}
        <p>{data}</p>

        <BsSendFill color="#4F46E5" />
      </div>
    );
  };
  return (
    <div className="suggestion-container">
      {questions
        ?.filter((item) => item.length)
        .map((item, index) => (
          <SuggestionItem
            key={index}
            data={item}
            lastItem={questions?.length - 1 === index}
          />
        ))}
    </div>
  );
};

export default SuggestionBox;
