import { createSlice } from "@reduxjs/toolkit";
import { getAllChats } from "../actions/getAllChats";
import { getChatHistory } from "../actions/getChatHistory";

const initialState = {
  allChats: [],
  activeChat: null,
  isChatMessagesLoading: false,
  isMessageSending: false,
  error: null,
  partialMessage: "",
};

const chatsSlice = createSlice({
  name: "chats",
  initialState,

  reducers: {
    addUserMessage: (state, action) => {
      if (state.activeChat) {
        state.activeChat.messages.push(action.payload);
      } else {
        state.activeChat = {
          messages: [action.payload],
        };
      }
      state.isMessageSending = true;
    },
    updateStreamingMessage: (state, action) => {
      state.isMessageSending = false;

      const existingBotMessage = state.activeChat.messages.find(
        (msg) => msg.role === "bot" && msg.streaming
      );
      if (existingBotMessage) {
        existingBotMessage.content = action.payload.content;
      } else {
        state.activeChat.messages.push({
          role: "bot",
          content: action.payload.content,
          streaming: true,
        });
      }
    },
    finalizeStreamingMessage: (state, action) => {
      const { data, isNewChat } = action.payload;

      const botMessage = state.activeChat.messages.find(
        (msg) => msg.role === "bot" && msg.streaming
      );
      if (botMessage) {
        botMessage.streaming = false;
      }

      if (isNewChat) {
        const {
          chat_id,
          auto_generated_name,
          creation_date,
          creation_month,
          documents_used,
          categories,
        } = data;

        state.activeChat = {
          ...state.activeChat,
          chat_id,
          auto_generated_name,
          creation_date,
          creation_month,
          documents: documents_used,
          categories,
        };

        // setting chat history in all chats

        const newChatData = {
          chat_id,
          auto_generated_name,
          creation_date,
          creation_month,
          documents: documents_used,
          categories,
        };
        const currentMonthValue = data.creation_month;
        const existingMonth = state.allChats.find(
          (chatMonth) => chatMonth.monthValue === currentMonthValue
        );

        if (existingMonth) {
          existingMonth.data.unshift(newChatData);
        } else {
          state.allChats.unshift({
            monthName: new Date(data.creation_date).toLocaleString("default", {
              month: "long",
              year: "numeric",
            }),
            monthValue: currentMonthValue,
            data: [newChatData],
          });
        }
      }
    },

    clearActiveChat: (state) => {
      state.activeChat = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllChats.fulfilled, (state, action) => {
        state.allChats = action.payload.chats;
      })

      .addCase(getChatHistory.pending, (state) => {
        state.isChatMessagesLoading = true;
        state.error = null;
      })
      .addCase(getChatHistory.fulfilled, (state, action) => {
        state.isChatMessagesLoading = false;

        // Setting activeChat with all relevant data from the fetched chat history
        state.activeChat = {
          chat_id: action.payload.chat_id,
          chat_title: action.payload.chat_title,
          total_messages: action.payload.total_messages,
          messages: action.payload.messages,
          documents_used: action.payload.documents_used,
        };
      });
  },
});

export const {
  addUserMessage,
  clearActiveChat,
  updateStreamingMessage,
  finalizeStreamingMessage,
} = chatsSlice.actions;

export default chatsSlice.reducer;
