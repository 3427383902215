import { createAsyncThunk } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import api from "../../services/api";
import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import { toast } from "react-toastify";

// Prop validation
const getChatHistoryArgsPropTypes = {
  chat_id: PropTypes.string.isRequired,
  limit: PropTypes.number,
  offset: PropTypes.number,
};

// Async thunk
export const getChatHistory = createAsyncThunk(
  "chats/getChatHistory",
  async (
    { chat_id, limit = 20, offset = 0 },
    { rejectWithValue, getState }
  ) => {
    const token = getState().user.token;

    try {
      const url = apiEndpoints.GET_CHAT_HISTORY(chat_id);
      const { data } = await api.get(url, {
        params: { limit, offset },
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      //BUG: need to add stock ticker, which was used in this chat
      return data;
    } catch (error) {
      toast.clearWaitingQueue();
      toast.dismiss();

      toast.error(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch chat history"
      );
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch chat history"
      );
    }
  }
);

// Prop validation check
export const validateGetChatHistoryArgs = (args) => {
  PropTypes.checkPropTypes(
    getChatHistoryArgsPropTypes,
    args,
    "prop",
    "getChatHistory"
  );
};
