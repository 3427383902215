import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api";
import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import { toast } from "react-toastify";

export const getSuggestedQuestions = createAsyncThunk(
  "chats/getSuggestedQuestions",
  async (chat_id = null, { rejectWithValue, getState }) => {
    const token = getState().user.token;

    try {
      const url = apiEndpoints.GET_CHAT_MESSAGE_SUGGESTIONS(chat_id);
      const { data } = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (error) {
      toast.clearWaitingQueue();
      toast.dismiss();

      toast.error(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch suggested Questions"
      );
      return rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch suggested Questions"
      );
    }
  }
);
