// src/store/index.js
import { configureStore } from "@reduxjs/toolkit";
import stocksReducer from "./slices/stocksSlice.js";
import chatsReducer from "./slices/chatsSlice.js";
import docsReducer from "./slices/docsSlice.js";
import userReducer from "./slices/userSlice.js";

const store = configureStore({
  reducer: {
    stocks: stocksReducer,
    chats: chatsReducer,
    docs: docsReducer,
    user: userReducer,
  },
});

export default store;

export * from "./slices/userSlice";
export * from "./slices/chatsSlice";
export * from "./slices/docsSlice";
export * from "./slices/stocksSlice";
export * from "./actions/loginUser";
export * from "./actions/getAllChats";
export * from "./actions/getAllDocs";
export * from "./actions/getChatHistory";
export * from "./actions/sendMessage";
export * from "./actions/uploadFiles";
export * from "./actions/getAllStockTickers";
export * from "./actions/setNewChat";
export * from "./actions/getSuggestedQuestion";
