import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "../actions/loginUser";

const token = localStorage.getItem("access_token");
const userInfo = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  isUserLoggedIn: !!token,
  userInfo: userInfo,
  token: token || null,
  isLoading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.isUserLoggedIn = false;
      state.userInfo = null;
      state.token = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("userInfo");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        const { access_token, user_id, profile_pic, name } = action.payload;

        state.isLoading = false;
        state.isUserLoggedIn = true;
        state.token = access_token;
        state.userInfo = {
          user_id,
          name,
          profile_pic,
        };

        localStorage.setItem("access_token", access_token);
        localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to login";
      });
  },
});

export const { logoutUser } = userSlice.actions;
export default userSlice.reducer;
