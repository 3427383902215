import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export function useAction(action) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  const runAction = useCallback(
    (arg) => {
      setIsLoading(true);
      setError(null);
      dispatch(action(arg))
        .unwrap()
        .then((result) => setData(result))
        .catch((err) => {
          toast.clearWaitingQueue();
          toast.dismiss();

          toast.error(err.message || "Something went wrong ");
          return setError(err);
        })
        .finally(() => setIsLoading(false));
    },
    [dispatch, action]
  );
  return [runAction, data, isLoading, error];
}
