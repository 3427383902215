import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../services/api";
import { apiEndpoints } from "../../utils/constants/apiEndPoints";
import { toast } from "react-toastify";

export const loginUser = createAsyncThunk(
  "user/login",
  async (token, { rejectWithValue }) => {
    const url = apiEndpoints.LOGIN_USER;
    const body = { token };

    try {
      const { data } = await api.post(url, body);
      return data;
    } catch (error) {
      toast.clearWaitingQueue();
      toast.dismiss();

      toast.error(error.response?.data || error.message || "Failed to login");
      return rejectWithValue(
        error.response?.data || error.message || "Failed to login"
      );
    }
  }
);
