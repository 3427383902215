import { createSlice } from "@reduxjs/toolkit";
import { clearActiveChat } from "./chatsSlice";
import { getAllStockTickers } from "../actions/getAllStockTickers";

const initialState = {
  allStocks: [],
  selectedStock: null,
  loading: false,
  error: null,
};

const stocksSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    setSelectedStock(state, action) {
      state.selectedStock = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllStockTickers.fulfilled, (state, action) => {
      state.allStocks = action.payload.stocks;
    });
  },
});

export const { setSelectedStock } = stocksSlice.actions;
export default stocksSlice.reducer;
